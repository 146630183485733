import React, { FC, useEffect } from 'react'
import { find, includes } from 'lodash'
import ReactHoverObserver from 'react-hover-observer'
import { useRouter } from 'next/router'
import Head from 'next/head'
import { ApolloProvider } from '@apollo/client'
import Interval from 'react-interval'
import { useAtom } from 'jotai'

import { useInitializeFaroClient } from '@docpace/shared-react-hooks/useFaro'
import {
    MANAGER_SIDEBAR_DRAWER_WIDTH,
    MANAGER_SIDEBAR_DRAWER_WIDTH_CLOSED,
    ManagerSidebar,
} from '@docpace/manager-react-components/ManagerLayout'
import { apolloClient } from '@docpace/apps/manager-app/client'
import { useNextQueryParam } from '@docpace/shared-react-hooks/useNextQueryParam'
import {
    EmptyPageMessage,
} from '@docpace/shared-react-components/EmptyPageMessage'
import {
    ProviderAppointmentDelayNotifier,
} from '@docpace/shared-react-components/ProviderAppointmentDelayNotifier'
import {
    useManagerBase,
} from '@docpace/shared-react-hooks/useManagerBase'
import { CheckInNotifier } from '@docpace/manager-react-components/CheckInNotifier'
import { useContextManagerBaseQuery, useProviderDashboardWarningNotifierQuery, useUpdateManagerMutation } from '@docpace/manager-react-apollo'
import { 
    ctxIsLoggingOut, 
    ctxIsSidebarOpen, 
    sharedAtomsPracticeReset,
    sharedUiAtomsPracticeReset,
    ctxPracticeIdAtom,
    ctxIsSidebarPinned,
    ctxPracticeName
} from '@docpace/shared-react-atoms'
import '@docpace/shared-react-styles/shared/layout.css'
import { makeActorDelayConfigs } from '@docpace/shared-ts-types/utils'
import { useManagerRequestFeedback } from '@docpace/manager-react-hooks'

export interface ManagerLayoutProps {
    children?: any
}

export const ManagerLayout: FC<ManagerLayoutProps> = (props) => {
    const router = useRouter()
    const { children } = props
    const [isSidebarPinned, setIsSidebarPinned] = useAtom(ctxIsSidebarPinned)
    const [, setCtxPracticeName] = useAtom(ctxPracticeName)
    const providerId = useNextQueryParam('providerId')
    const { FeedbackRequestComponent, shouldDisplayFeedbackRequest } = useManagerRequestFeedback()
    const [ isLoggingOut ] = useAtom(ctxIsLoggingOut)
    const [ isSidebarOpen, setIsSidebarOpen ] = useAtom(ctxIsSidebarOpen)
    const isAuthPage = router?.pathname?.split('/')?.[1] === 'auth'
    useContextManagerBaseQuery({ isAuthPage })

    useAtom(sharedAtomsPracticeReset)
    useAtom(sharedUiAtomsPracticeReset)

    const {
        practice,
        isLoading: isManagerBaseLoading,
        providers,
        adminId,
        managerId,
        admin,
        manager,
        hasNetworkError,
        isInitialized,
        actor,
        managerPracticeBaseQueryRefetch,
        showMonthlyReport,
        showPatientFlowReport,
        showCheckoutTable,
        showInsightsLinks,
        insightsRequiresUpgrade,
        isProd,
    } = useManagerBase()

    useInitializeFaroClient({ 
        appName: 'manager-app-events',
        practiceId: practice?.practiceId,
        userId: adminId ?? managerId,
        url: isProd ? 'https://alloy.docpaceproduction.com/collect'
        : 'https://alloy.staging.docpacedev.com/collect'
    })


    const delayConfigs = makeActorDelayConfigs(actor)
    const provider = find(providers, { providerId })

    const practiceNameOverride = typeof window !== 'undefined' ? localStorage.getItem('practiceName') : null
    const practiceName = practiceNameOverride ?? practice?.name
    useEffect(()=>{ setCtxPracticeName(practiceName) }, [practiceName])
    const showSidebar = !isAuthPage && router.pathname !== '/' && showInsightsLinks
    const sidebarMargin = showSidebar
        ? isSidebarPinned && isSidebarOpen
            ? MANAGER_SIDEBAR_DRAWER_WIDTH
            : MANAGER_SIDEBAR_DRAWER_WIDTH_CLOSED
        : 0

    const practiceId = practice?.practiceId
    const [ , setContextPracticeId ] = useAtom(ctxPracticeIdAtom)
    useEffect(()=>{ setContextPracticeId(practiceId) }, [ practiceId ])

    const { managerSiteMessage } = useProviderDashboardWarningNotifierQuery({ 
        practiceId, 
        skip: !practiceId,
        providerIds: providerId?.split(',')
    })

    if (isLoggingOut)
        return (
            <div className="h-screen w-screen flex items-center">
                <EmptyPageMessage
                    message={'Logging out...'}
                    messageLineTwo={
                        'You will be redirected to the sign-in page shortly.'
                    }
                />
            </div>
        )

    if (!isInitialized && !isAuthPage)
        return (
            <div className="h-screen w-screen flex items-center">
                <EmptyPageMessage id='initializing-message' message={'Initializing practice...'} />
            </div>
        )

    if (isInitialized && !practice && !isManagerBaseLoading && !isAuthPage)
        return (
            <div className="h-screen w-screen flex items-center">
                <EmptyPageMessage
                    message={`You do not appear to have access to this practice.`}
                    messageLineTwo={`Please contact your administrator.`}
                />
            </div>
        )
    const showManagerSiteMessage = managerSiteMessage?.length > 0

    return (
        <ApolloProvider client={apolloClient}>
            {/* <ThemeProvider theme={adminTheme}> */}
            <div style={{ width: '100vw' }}>
                <Head>
                    <title>DOCPACE Manager Dashboard</title>
                    <meta
                        name="viewport"
                        content="initial-scale=1.0, width=device-width"
                    />
                </Head>

                {showSidebar && (
                    <ReactHoverObserver>
                        <ManagerSidebar
                            practiceId={practice?.practiceId}
                            providerId={providerId}
                            isOpen={isSidebarOpen}
                            isPinned={isSidebarPinned}
                            setIsSidebarPinned={setIsSidebarPinned}
                            setIsSidebarOpen={setIsSidebarOpen}
                            showInsightsLinks={showInsightsLinks}
                            insightsRequiresUpgrade={insightsRequiresUpgrade} // todo make this an atom?
                            showPatientFlowReport={showPatientFlowReport}
                            showMonthlyReport={showMonthlyReport}
                            showCheckoutTable={showCheckoutTable}
                        />
                    </ReactHoverObserver>
                )}

                <Interval
                    enabled
                    timeout={12 * 60 * 1000} // 12m, enough to trigger session refreshes // todo use a lighter query and lower timeout?
                    callback={() => {
                        managerPracticeBaseQueryRefetch().then(() => {})
                    }}
                />

                <div
                    style={{
                        width: `calc(100vw - ${sidebarMargin}px)`,
                        height: '100vh',
                        display: 'flex',
                        flexDirection: 'column',
                        marginLeft: sidebarMargin,
                    }}
                >
                    { showManagerSiteMessage && <div className='w-full flex justify-center p-0.5' style={{ backgroundColor: '#ff9966'}}>
                        { managerSiteMessage }
                    </div> }
                    {
                        !showManagerSiteMessage && shouldDisplayFeedbackRequest && <FeedbackRequestComponent />
                    }
                    {/* {!isAuthPage && (
                        <ManagerHeader
                            subtitle={subtitle ?? (<div>
                                    {!practice && (
                                        <div className="cursor-pointer hover:none">
                                            Manager Dashboard
                                        </div>
                                    )}
                                    {practice && (
                                        <Link href={homeLink} className="cursor-pointer hover:underline" id='home-link'>
                                        {practiceName +
                                            ' Manager Dashboard'}
                                        </Link>
                                    )}
                                </div>)
                            }
                            title={title}
                        />
                    )} */}
                    {/* { isChangingRoute && <div className="w-full h-full fixed block top-0 left-0 bg-white opacity-75 z-50">
                        <span className="text-green-500 opacity-75 top-1/2 my-0 mx-auto block relative w-0 h-0" style={{
                            top: '50%'
                        }}>
                            <i className="fas fa-circle-notch fa-spin fa-5x"></i>
                        </span>
                    </div> */}
                    {isAuthPage || !!practice ? (
                        children
                    ) : (
                        <>
                            {hasNetworkError && (
                                <EmptyPageMessage
                                    message={
                                        'There was a problem communicating with the API'
                                    }
                                    messageLineTwo={
                                        'Please try again later. If the problem persists, contact DOCPACE support.'
                                    }
                                />
                            )}
                        </>
                    )}
                </div>

                {providers?.map((checkInProvider) => (
                    <CheckInNotifier
                        manager={manager}
                        provider={checkInProvider}
                        key={checkInProvider?.providerId}
                        providers={providers}
                        currentPageProviderIds={[provider?.providerId]}
                        usePatientInitials={
                            actor?.managerDisplayPatientInitialsOnly
                        }
                    />
                ))}

                {(actor?.managerNotifyWaitForIntakeDelays ||
                    actor?.managerNotifyWaitForDoctorDelays) &&
                    providers?.map((delayProvider) => (
                        <ProviderAppointmentDelayNotifier
                            key={delayProvider?.providerId}
                            manager={manager}
                            provider={delayProvider}
                            usePatientInitials={
                                actor?.managerDisplayPatientInitialsOnly
                            }
                            isSelected={includes(
                                providerId?.split(','),
                                delayProvider?.providerId
                            )}
                            delayConfig={delayConfigs}
                        />
                ))}
            </div>
            {/* </ThemeProvider> */}
        </ApolloProvider>
    )
}
